<template>
  <div class="container">
    <van-icon name="sign" size="6rem" color="#455a6499" />
    <div class="block-title">{{ $t("用户注册") }}</div>
    <van-form @submit="onRegister">
      <van-field
        v-model="data.mobile"
        :label="$t('电话')"
        :placeholder="$t('请输入电话号码')"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="data.nickname"
        :label="$t('昵称')"
        :placeholder="$t('请输入昵称')"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="data.password"
        type="password"
        :label="$t('密码')"
        :placeholder="$t('请输入密码')"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="data.smsCaptcha"
        center
        clearable
        :label="$t('短信验证码')"
        :placeholder="$t('请输入短信验证码')"
        :rules="[{ required: true }]"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            native-type="button"
            :disabled="smsBtn.disabled"
            @click="onSendSms"
            >{{ smsBtn.text }}</van-button
          >
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          :loading="regBtnLoading"
          native-type="submit"
          >{{ $t("注册") }}</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Field, Button, Icon } from "vant";

export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Icon.name]: Icon,
  },

  data() {
    return {
      smsBtn: {
        disabled: false,
        text: this.$t("发送验证码"),
        counter: 0,
      },
      regBtnLoading: false,
      data: {
        mobile: "",
        nickname: "",
        password: "",
        smsCaptcha: "",
      },
    };
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.hideBadge();
    });
  },
  methods: {
    async onSendSms() {
      if (!this.data.mobile || this.data.mobile.length < 1) {
        this.$toast.fail(this.$t("请输入电话号码"));
        return;
      }

      let captchaToken = await this.getCaptchaToken();
      let res = await this.$userApi.sms.sendSmsCaptcha({
        type: 1,
        mobile: this.data.mobile,
        captcha: captchaToken,
      });
      if (res.status == 200) {
        this.smsBtn.disabled = true;
        this.smsBtn.counter = 60;
        this.onSmsBtnTiming();
      }
    },
    onSmsBtnTiming() {
      if (this.smsBtn.counter > 0) {
        this.smsBtn.text = this.$t("{0}秒后重试", [this.smsBtn.counter--]);
        setTimeout(this.onSmsBtnTiming, 1000);
      } else {
        this.smsBtn.disabled = false;
        this.smsBtn.text = this.$t("发送验证码");
      }
    },
    async onRegister() {
      this.regBtnLoading = true;
      try {
        let res = await this.$userApi.customer.register(this.data);
        if (res.status == 200) {
          await this.$dialog.alert({
            message: this.$t("恭喜，您已注册成功"),
          });
          this.$router.push("my");
        }
      } finally {
        this.regBtnLoading = false;
      }
    },
    async getCaptchaToken() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("sendSms");
      return token;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding-top: 5rem;
  text-align: center;

  .block-title {
    font-size: 1rem;
    margin: 1rem 0;
  }
}

.reg-link {
  text-align: center;
  color: #1989fa;
}
</style>